const kontakt = {
  title: "Kontakt",
  company: "Von Mauch",
  firstname: "Philipp",
  lastname: "von Mauch",
  address: {
    street: "Am Feuerschwanden 6",
    plz: "88316",
    location: "Close to Munich",
    country: "Deutschland",
  },
  email: "hello@vonmauch.com",
  phone: "+49 170 112 1337",
  mobile: "+49 170 112 1337",
  fax: "xxxx",
  googlemaps: "https://goo.gl/maps/FErDBm7SE4YDz5cQ6",
  linkedin: "https://www.linkedin.com/in/philipp-von-mauch-b11131187",
  xing: "https://www.xing.com/profile/Philipp_vonMauch",
  facebook: "no fb",
  instagram: "no insta",
  date: {
    day_1: "",
  },
 
};

export default kontakt;
